import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IProduct} from 'app/blocks/model/product.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IProductionVoucherItem {
    id?: number;
    product?: IProduct;
    quantity?: number;
    rate?: number;
    amount?: number;
    overheadCost?: number;
    finalCostPrice?: number;
    netAmount?: number;
    productType?: string;
}

export interface IProductionVoucher extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    date?: any;
    warehouse?: IWarehouse;
    status?: string;
    items?: IProductionVoucherItem[];
    sourceTotalQuantity?: number;
    sourceAmountTotal?: number;
    destTotalQuantity?: number;
    destAmountTotal?: number;
    destNetAmountTotal?: number;
    destNetOverheadCost?: number;
    approveOnCreateOrUpdate?: boolean;
}

export class ProductionVoucherItem implements IProductionVoucherItem {
    public id?: number;
    public product?: IProduct;
    public quantity?: number;
    public rate?: number;
    public amount?: number;
    public overheadCost?: number;
    public finalCostPrice?: number;
    public netAmount?: number;
    public productType?: string;

    constructor(productionVoucherItem?) {
        if (productionVoucherItem) {
            this.id = productionVoucherItem.id;
            this.product = productionVoucherItem.product;
            this.quantity = productionVoucherItem.quantity;
            this.rate = productionVoucherItem.rate;
            this.amount = productionVoucherItem.amount;
            this.overheadCost = productionVoucherItem.overheadCost;
            this.finalCostPrice = productionVoucherItem.finalCostPrice;
            this.netAmount = productionVoucherItem.netAmount;
            this.productType = productionVoucherItem.productType;
        }
    }
}

export class ProductionVoucher extends BaseEntity implements IProductionVoucher {
    public id?: number;
    public name?: string;
    public description?: string;
    public warehouse?: IWarehouse;
    public status?: string;
    public items?: IProductionVoucherItem[];
    public sourceTotalQuantity?: number;
    public sourceAmountTotal?: number;
    public destTotalQuantity?: number;
    public destAmountTotal?: number;
    public destNetAmountTotal?: number;
    public destNetOverheadCost?: number;
    public date?: any;
    public approveOnCreateOrUpdate?: boolean;

    constructor(productionVoucher?) {
        super();
        if (productionVoucher) {
            this.id = productionVoucher.id;
            this.name = productionVoucher.name;
            this.description = productionVoucher.description;
            this.warehouse = productionVoucher.warehouse;
            this.status = productionVoucher.status;
            this.items = productionVoucher.items;
            this.sourceTotalQuantity = productionVoucher.sourceTotalQuantity;
            this.sourceAmountTotal = productionVoucher.sourceAmountTotal;
            this.destTotalQuantity = productionVoucher.destTotalQuantity;
            this.destAmountTotal = productionVoucher.destAmountTotal;
            this.destNetAmountTotal = productionVoucher.destNetAmountTotal;
            this.destNetOverheadCost = productionVoucher.destNetOverheadCost;
            this.date = MposDate.newInstance(productionVoucher.date);
            this.approveOnCreateOrUpdate = productionVoucher.approveOnCreateOrUpdate;
        } else {
            this.status = 'DRAFT';
            this.sourceTotalQuantity = 0;
            this.sourceAmountTotal = 0;
            this.destTotalQuantity = 0;
            this.destAmountTotal = 0;
            this.destNetAmountTotal = 0;
            this.destNetOverheadCost = 0;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ProductionVoucher {
        return new ProductionVoucher(this);
    }
}
